import IndexNavbar from "../components/Navbars/IndexNavbar";
import React from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    Col,
    Container,
    Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../components/Footers/Footer";
import {faFacebook, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import EmailBlock from "../components/EmailBlock";

function AboutMe() {

    return (
        <>
            <IndexNavbar />
            <div className="main pt-5">
                <div className="section text-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title-yuri-4">Qui suis-je ?</h2>
                                <Card className="card-profile card-plain pt-5" >
                                    <div className="card-avatar">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/faces/dia-2.jpg")}
                                        />
                                    </div>
                                    <CardBody>
                                        <div className="author">
                                            <h3 className="title-yuri-2">Diahara Lansry</h3>
                                            <h6 className="card-category">Développeur sénior</h6>
                                        </div>
                                        <p className="card-description text-center">
                                            Forts de dix années d'expérience en tant que développeur, dont une période de cinq ans en tant que freelance, J'ai eu le privilège de collaborer avec de prestigieux groupes tels que La Poste, PSA et La CNAM
                                            en qualité de consultante pour Capgemini (Sogeti), ainsi que de freelance pour COLAS et d'autre client, j'ai apporté mon expertise à ces entreprises renommées. <br/>Cette décennie d'expérience a été marquée par l'acquisition de compétences approfondies me permettant de vous fournir des conseils techniques pertinents et de développer des applications web et mobiles de qualité supérieure.
                                        </p>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Row>
                                            <Col>
                                                <Button
                                                    className="btn-just-icon btn-neutral ml-1"
                                                    color="link"
                                                    href="https://web.facebook.com/p/Foulane-100078555725948/?_rdc=1&_rdr"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <FontAwesomeIcon icon={faFacebook} className="fa-icon-index-color"/>
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className="btn-just-icon btn-neutral ml-1"
                                                    color="link"
                                                    href="https://fr.linkedin.com/in/diahara-lansry-2015a0165"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <FontAwesomeIcon icon={faLinkedinIn} className="fa-icon-index-color"/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-primary section-information text-center">
                    <Container>
                        <h3 className="title-yuri-2-white">Mes expériences</h3><br/><br/>
                        <h5 className="title-yuri-1-white">En tant que freelance</h5>
                        <p>
                            En plus de mes clients actuels, je consacre également une partie de mon temps à travailler sur mes propres projets. Cette initiative personnelle me permet d'explorer de nouvelles idées, de repousser les limites de la créativité et de rester constamment en apprentissage. <br/>
                            En tant que professionnel passionné, j'ai toujours été attiré par l'innovation et la recherche de solutions novatrices. Ainsi, investir dans mes propres projets me permet de mettre en pratique mes compétences et mon expertise tout en développant de nouvelles compétences et en explorant de nouveaux domaines.</p>
                        <Row className={"pb-5 pt-5"}>
                            <Col lg="6" className={"pt-4"}>
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/logo-k-tech.png")}
                                            width="50%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>KISIBA Tech, filiale du groupe KISIBA Global, est une startup évoluant dans le domaine des technologies de l’information au Mali.  Nous développons des solutions technologiques innovantes et personnalisées pour les entreprises de la place. </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" className={"pt-4"}>
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/Sans_titre__3_-removebg-preview (2).png")}
                                            width="25%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Etre un espace d’échange d’expériences de vie, pour l’accomplissement intérieur.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row><br/><br/>
                        <h5 className="title-yuri-1-white">En tant que consultante</h5>
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/1200px-Sogeti-logo-2018.svg.png")}
                                            width="50%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <p>Pendant les six dernières années, j'ai eu le privilège d'être employée en tant que consultante en développement, bénéficiant d'un contrat à durée indéterminée (CDI). Au cours de cette période, j'ai eu l'opportunité de collaborer avec différentes entreprises renommées, ce qui m'a permis d'acquérir une expérience variée et d'élargir mes compétences dans le domaine du développement.</p>

                        <Row className="pt-5">
                            <Col lg="4" className={"pt-4"}>
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/1280px-La_Poste_logo.svg.png")}
                                            width="50%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>
                                            Pendant mon séjour chez La poste, j'ai été impliquée dans des projets de développement d'applications web de tri des courriers et colis TRACEO / UTE TRACING de grande envergure. J'ai travaillé en étroite collaboration avec une équipe multidisciplinaire pour créer des solutions innovantes et répondre aux besoins spécifiques du client.
                                            </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" className={"pt-4"}>
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/1200px-Groupe_PSA_logo.svg.png")}
                                            width="25%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>En tant que consultante chez PSA, j'ai participé à la refonte du site peugeot. J'ai contribué à la conception et à la mise en œuvre de systèmes qui ont permis d'améliorer l'efficacité du site.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" className={"pt-4"}>
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/ASSURANCE_MALADIE.svg.png")}
                                            width="50%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Lors de mon mandat chez la Caisse d'assurance maladie, j'ai été responsable du développement d'applications dans le domaine de la santé. J'ai travaillé sur des projets complexes visant à améliorer l'accessibilité des soins de santé et à faciliter la communication entre les professionnels de la santé et les patients.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="pt-5">
                            <p>
                                Ces expériences professionnelles m'ont permis de développer des compétences solides en programmation, en gestion de projet et en résolution de problèmes. J'ai acquis une compréhension approfondie des défis liés au développement d'applications et j'ai développé une approche axée sur la qualité, la collaboration et la satisfaction du client. <br/>
                                Mon parcours en tant que consultante en développement m'a donné une vision holistique de l'industrie et m'a permis de m'adapter rapidement à différents environnements de travail. Forte de cette expérience, je suis maintenant prête à relever de nouveaux défis et à contribuer à des projets passionnants au sein de votre entreprise.
                            </p>
                        </div>
                        <div className="pt-5">
                            <h3 className="title-yuri-1-white text-center">Mes Connaissances techniques</h3>
                            <br />
                            <Row className={"pb-5 pt-5"}>
                                <Col md="3" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/php.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="2" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/node.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="3" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/flutter.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="3" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/react.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="3" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/react-native.jpeg")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="3" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/firebase.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/mongo.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="2" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/redis.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="3" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/mysql.png")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="2" className={"pt-4"}>
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/techno/docker.jpeg")}
                                                width="50%"
                                                style={{borderRadius: 20}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="pt-5">
                            <h3 className="title-yuri-1-white text-center">Mes réalisations</h3>
                            <br />
                            <Row>
                                <Col lg="2">
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/app/s2.jpg")}
                                                width="100%"
                                                style={{borderRadius: 20}}
                                            />
                                            <br/>
                                            <br/>
                                            <p>Site web de petites annonces</p>
                                            <Button className="btn-link" color="info" href="https://www.dealsamax.com" target="_blank">
                                                Découvrir
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/app/s4.png")}
                                                width="100%"
                                                style={{borderRadius: 20}}
                                            />
                                            <br/>
                                            <br/>
                                            <p>Marketplace de vente de produits en ligne dans toute l'afrique. Mode, parfum, accessoire etc...</p>
                                            <Button className="btn-link" color="info" href="https://www.foulane.com" target="_blank">
                                                Découvrir
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/app/s7.png")}
                                                width="100%"
                                                style={{borderRadius: 20}}
                                            />
                                            <br/>
                                            <br/>
                                            <p>Site d'intérim ...</p>
                                            <Button className="btn-link" color="info" href="https://lansry-rh.fr/" target="_blank">
                                                Découvrir
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/app/s1.png")}
                                                width="100%"
                                                style={{borderRadius: 20}}
                                            />
                                            <br/>
                                            <br/>
                                            <p>
                                                Application web de prise de commande par le client et listing des plats disponibles
                                            </p>
                                            <Button className="btn-link" color="info">
                                                En cours de dévélooppement
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/app/s3.png")}
                                                width="100%"
                                                style={{borderRadius: 20}}
                                            />
                                            <br/>
                                            <br/>
                                            <p>Application de gestion d'une buanderie. Notification collecte, livraison, gestion de facture, paye ...</p>
                                            <Button className="btn-link" color="info">
                                                Store
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="info">
                                        <div className="description">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/app/HD.png")}
                                                width="100%"
                                                style={{borderRadius: 20}}
                                            />
                                            <br/>
                                            <br/>
                                            <p>Site web de reservation d'évènement chez HAIIDESIGNS</p>
                                            <Button className="btn-link" color="info" href="#" target="_blank">
                                                En cours de dévélooppement
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <EmailBlock
                    title={"Nous contacter"}
                    description={"Que vous soyez un entrepreneur passionné, un talentueux développeur ou une entreprise en quête d'innovation, nous sommes prêts à écouter vos idées, à explorer de nouvelles possibilités et à trouver des solutions adaptées à vos besoins spécifiques."}
                    description2={"Pour contacter Yuri, vous pouvez simplement envoyer un mail au contact ci-dessous en précisant l'objet de votre demande. Nous nous engageons à vous répondre dans les plus brefs délais et à explorer les différentes façons dont nous pouvons collaborer"}
                    objects={[
                        "Entreprise en quête d'innovation",
                        "Entrepreneur passionné",
                        "Développeur talentueux"
                    ]}
                />
            </div>
            <Footer />
        </>
    )
}

export default AboutMe;