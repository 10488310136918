import IndexNavbar from "../components/Navbars/IndexNavbar";
import React from "react";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import Footer from "../components/Footers/Footer";
import EmailBlock from "../components/EmailBlock";

function Pricing() {

    return (
        <>
            <IndexNavbar />
            <div className="main pt-5">
                <div className="section text-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title-yuri-4">Tarifications</h2> <br/><br/>
                                <p className="description text-center">
                                    La tarification des services de développement de sites web statiques, dynamiques et mobiles est basée sur une évaluation approfondie de vos besoins spécifiques et des fonctionnalités requises pour votre projet. Chaque projet est unique, et par conséquent, le coût de développement peut varier en fonction de plusieurs facteurs clés.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-primary section-information text-center">
                    <Container>
                        <p>
                            1.Complexité du site web <br/><br/>
                            La complexité de votre site web joue un rôle majeur dans la tarification. Les sites web statiques, qui sont composés de pages HTML et CSS sans interactivité complexe, tendent à être moins coûteux que les sites web dynamiques, qui nécessitent des fonctionnalités avancées telles que des bases de données, des systèmes de gestion de contenu (CMS) ou des fonctionnalités e-commerce. Les sites web mobiles peuvent également présenter des considérations supplémentaires en termes de conception et d'optimisation pour les appareils mobiles.
                            <br/><br/><br/>
                            2.Nombre de pages <br/><br/>
                            Le nombre de pages que vous souhaitez inclure sur votre site web peut également influencer la tarification. Un site web avec un petit nombre de pages sera moins coûteux à développer qu'un site web plus complexe comprenant de nombreuses pages avec une mise en page personnalisée pour chacune.
                            <br/><br/><br/>
                            3.Design et personnalisation <br/><br/>
                            Si vous avez des exigences de conception spécifiques ou si vous souhaitez une personnalisation approfondie de l'apparence de votre site web, cela peut entraîner des coûts supplémentaires. Un design personnalisé nécessite plus de travail et de ressources pour le développement.
                            <br/><br/><br/>
                            4.Fonctionnalités supplémentaires <br/><br/>
                            Si vous avez besoin de fonctionnalités supplémentaires telles que des formulaires de contact, des galeries d'images, des intégrations de médias sociaux, des fonctionnalités de blog, des systèmes de réservation en ligne ou toute autre fonctionnalité spécifique, cela peut également influencer le coût global du projet.
                            <br/><br/><br/>
                            5.Maintenance et mises à jour<br/><br/>
                            La tarification peut également inclure des services de maintenance, de support et de mises à jour régulières pour assurer le bon fonctionnement continu de votre site web. Ces services peuvent être facturés sous forme de forfaits mensuels ou annuels.
                        </p>
                    </Container>
                </div>
                <EmailBlock
                    title={"Avoir un devis"}
                    description={"Nous comprennons à quel point il est important d'obtenir un devis précis et détaillé dans le cadre de la réalisation d'une application mobile ou web. Chez Yuri, nous nous engageons à vous fournir une proposition transparente qui reflète vos besoins spécifiques et votre vision. Pour commencer, nous vous invitons à nous fournir quelques détails sur votre projet."}
                    description2={"Plus nous en saurons sur vos objectifs, vos fonctionnalités souhaitées et vos exigences techniques, plus notre devis sera précis et personnalisé. Vous pouvez nous communiquer ces informations en nous envoyant un mail."}
                    description3={"Lors de la préparation de votre devis, notre équipe d'experts analysera attentivement les éléments suivants :"}
                    lists={[
                        "1.Spécifications du projet : Nous examinerons les fonctionnalités que vous souhaitez intégrer dans votre application, telles que les fonctionnalités de base, les options de personnalisation, les intégrations avec d'autres systèmes, etc. ",
                        "2.Conception et interface utilisateur : Nous tiendrons compte de l'expérience utilisateur (UX) et de l'interface utilisateur (UI) pour créer une application esthétiquement attrayante et conviviale.",
                        "3.Plateforme et compatibilité : Nous évaluerons les plateformes cibles pour votre application (iOS, Android, web) et veillerons à ce que l'application soit compatible avec les versions les plus récentes des systèmes d'exploitation.",
                        "4.Développement et programmation : Nous prendrons en compte les exigences techniques pour le développement de votre application, y compris les langages de programmation, les bases de données, les frameworks et les outils nécessaires.",
                        "5.Tests et assurance qualité : Nous inclurons une phase de tests rigoureuse pour assurer la qualité et la performance de votre application avant son déploiement.",
                        "6.Maintenance et support : Nous proposerons des options de maintenance et de support pour garantir le bon fonctionnement de votre application à long terme."
                    ]}
                    objects={[
                        "Entreprise en quête d'innovation",
                    ]}
                />
            </div>
            <Footer />
        </>
    )

}

export default Pricing;