function Email (name, email, phone, object, description) {

    return (
        `<body bgcolor="#f5f5f5" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0" offset="0"
              style="padding:70px 0 70px 0;">
            <table width="600" height="auto" align="center" cellPadding="0" cellSpacing="0"
                   style="background-color:#fdfdfd; border:1px solid #dcdcdc; border-radius:3px !important;">
                <tr>
                    <td width="600" height="auto" border="0"
                        style="padding:36px 48px; display:block; margin: 0px auto; background-color: #000000">
                        <h1 style="color:#ffffff; font-family:&quot; Avenidastdregular&quot;,Helvetica,Arial,Lucida,sans-serif; font-size:30px; line-height:150%; font-weight:300; margin:0; text-align:left;">Yuri agence</h1>
                    </td>
                </tr>
                <tr>
                    <td width="600" bgcolor="#fdfdfd" border="0"
                        style="color:#737373; font-family:&quot;Helvetica Neue&quot;,Helvetica,Roboto,Arial,sans-serif; font-size:14px; line-height:150%; text-align:left; padding:48px;">
                        <p style="margin:0 0 16px;">Nouvelle demande depuis yuri-agence<br/><br/>
                            <br/>Nom et prénom du client : <b>${name}</b>
                            <br/>Email client : <b>${email}</b>
                            <br/>Numéro du client : <b>${phone}</b>
                            <br/>Object du mail : <b>${object}</b>
                        </p>
                        <p style="margin:0 0 16px;">
                            <br/>Demande : <b>${description}</b>
                        </p>
                    </td>
                </tr>
            </table>
        </body>`
    )
}

export default Email;