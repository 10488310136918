import React, {useEffect, useState} from "react";
import {
    Alert, Button,
    Col,
    Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
    Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMailBulk, faTextHeight, faSpinner, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import app from '../firebase';
import {getFunctions, httpsCallable} from "firebase/functions";
import Email from "./Email";

const functions = getFunctions(app);

function EmailBlock({title, objects, description, description2, description3, lists}) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [object, setObject] = useState("");
    const [text, setText] = useState("");
    const [code, setCode] = useState("");
    const [codeGenerate, setCodeGenerate] = useState(0);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Veuillez remplir tous les champs");

    useEffect( () => {
        generateCode();
    }, [])

    const handlerOnValidate = () => {
        setLoading(true);
        if("" === name && "" === email && "" === object && "" === text && "" === code) {
            setLoading(false);
            setError(true);
            setMessage("Veuillez remplir tous les champs");
            return;
        }

        if(parseInt(code) !== codeGenerate) {
            setLoading(false);
            setLoading(false);
            setMessage("Êtes-vous un robot ? Le code ne correspond pas.");
            setError(true);
            return;
        }

        setError(false);
        const sendEmail = httpsCallable(functions, 'sendEmail');
        const obj = objects[parseInt(object)];
        try {
            sendEmail({
                from: email,
                subject: '[YURI-AGENCE] - DEMANDE DE DEVIS : ' + obj,
                text: Email(name, email, phone, obj, text)
            })
                .then(result => {
                    if (result.data.success) {
                        setLoading(false)
                        setSuccess(true);
                        setMessage('Email envoyé. Votre demande recevra une réponse en moins d\'une heure. Merci');
                        console.log('E-mail envoyé avec succès');

                        setName("");
                        setText("");
                        setObject("");
                        setEmail("");
                        setPhone("");
                        setCode("");
                        generateCode();

                    } else {
                        setError(true);
                        setMessage('Erreur lors de l\'envoi de l\'e-mail :' + result.data.error);
                        console.error('Erreur lors de l\'envoi de l\'e-mail :', result.data.error);
                    }
                })
                .catch(error => {
                    setError(true);
                    console.error('Erreur lors de l\'appel de la fonction Cloud :', error);
                    setMessage('Erreur lors de l\'appel de la fonction Cloud : ' + error);
                });
        } catch (e) {
            console.log(e);
            setMessage(e);
            setError(true);
            setSuccess(false);
        }
    }

    const generateCode = () => {
        const min = 1;
        const max = 1000000;
        const rand = Math.floor(Math.random() * (max - min) + min);

        setCodeGenerate(rand);
    }

    return (
        <div className="section landing-section">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        <h2 className="text-center title-yuri-4">{title}</h2> <br/>
                        <p className="description text-center">{description}</p>
                        <p className="description text-center">{description2}</p> <br/>
                        {
                            description3 && (
                                <p className="description text-center">{description3}</p>
                            )
                        }
                        {
                            lists && (
                                lists.map((list, index) => {
                                    return <p key={index} className="description text-center">{list}</p>
                                })
                            )
                        }
                        <div className="text-center pb-5">
                            <h5 className="title-yuri-2">N'hésitez pas à me joindre directement via WhatsApp.</h5>
                            <p className="description">En appuyant sur l'icône WhatsApp ci-dessous</p><br/><br/>
                            <b className="font-weight-bold text-uppercase">
                                <a href="https://wa.me/33648345315">
                                    <FontAwesomeIcon icon={faWhatsapp} style={{fontSize: 50}}/>
                                </a>
                            </b>
                        </div>
                        <div className="text-center pb-5">
                            <h5 className="title-yuri-2">Ou en complétant le formulaire ci-dessous</h5>
                            <p className="description">Votre demande recevra une réponse en moins d'une heure.</p>
                        </div>
                        <div className="pt-3 pb-5">
                            {
                                error && (
                                    <Alert color="danger" isOpen={error} toggle={(e) => setError(!error)}>
                                        <b className="font-weight-bold">Ooups!! </b> {message}
                                    </Alert>
                                )
                            }
                            {
                                success && (
                                    <Alert color="success" isOpen={success} toggle={(e) => setSuccess(!success)}>
                                        <b className="font-weight-bold">Youpi!! </b> {message}
                                    </Alert>
                                )
                            }
                        </div>
                        <Row>
                            <Col md="6" className="pb-3">
                                <label>Nom & Prénom *</label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faTextHeight} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={name} placeholder="Nom & Prénom" type="text" onChange={(e) => setName(e.target.value)}/>
                                </InputGroup>
                            </Col>
                            <Col md="6" className="pb-3">
                                <label>Numéro de téléphone (whatsApp de préférence)</label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faPhone} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={phone} placeholder="Numéro de téléphone" type="number" onChange={(e) => setPhone(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <div className="pb-3">
                            <label>Email *</label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faMailBulk} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input value={email} placeholder="Email" type="text" onChange={(e) => setEmail(e.target.value)}/>
                            </InputGroup>
                        </div>
                        <FormGroup className="pb-3">
                            <Label for="badge" className="control-label">Objet *</Label>
                            <Input type="select" name="select" id="badge" onChange={(e) => setObject(e.target.value)} >
                                <option>Sélectionner un objet</option>
                                {
                                    objects.map((element, index) => <option key={index} value={index}>{element}</option>)
                                }
                            </Input>
                        </FormGroup>

                        <div className="pb-3">
                            <label>Message</label>
                            <Input
                                placeholder="Message ..."
                                type="textarea"
                                rows="4"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </div>

                        <div className="pb-3">
                            <label>Veuillez recopier ce que vous lisez * : <b className="font-weight-bold">{codeGenerate}</b></label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faTextHeight} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input value={code} placeholder="Code" type="number" onChange={(e) => setCode(e.target.value)}/>
                            </InputGroup>
                        </div>
                        <Row>
                            <Col>
                                <Button className="btn-fill" color={!loading ? "site" : "default"} onClick={!loading ? handlerOnValidate : null} style={{float: "right"}}>
                                    {
                                        loading ? "Envoie du mail en cours ...." : "Valider le formulaire"
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}

export default EmailBlock;