import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {ROUTE_CV, ROUTE_HOME, ROUTE_PRICING, ROUTE_PRIVACY_POLICY} from "../../Routes";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent navbar-index");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
          document.documentElement.scrollTop > 299 ||
          document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
          document.documentElement.scrollTop < 300 ||
          document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent navbar-index");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
      <Navbar
          className={classnames("fixed-top", navbarColor)}
          color-on-scroll="300"
          expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
                className="title-yuri"
                data-placement="bottom"
                to={ROUTE_HOME}
                title="Yuri agence"
                tag={Link}
            >
              Yuri Agence
            </NavbarBrand>
            <button
                aria-expanded={navbarCollapse}
                className={classnames("navbar-toggler navbar-toggler", {
                  toggled: navbarCollapse
                })}
                onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
              className="justify-content-end"
              navbar
              isOpen={navbarCollapse}
          >
            <Nav navbar>
              <NavItem>
                <a className="nav-link" href={ROUTE_CV}>A propos de moi</a>
              </NavItem>
              <NavItem>
                <a className="nav-link" href={ROUTE_PRICING}>Tarification</a>
              </NavItem>
              <NavItem>
                <a className="nav-link" href={ROUTE_PRIVACY_POLICY}>Politique de confidentialité</a>
              </NavItem>
              <NavItem>
                <NavLink
                    data-placement="bottom"
                    href="https://web.facebook.com/p/Foulane-100078555725948/?_rdc=1&_rdr"
                    target="_blank"
                    title="Facebook"
                >
                  <FontAwesomeIcon icon={faFacebook} className="fa-icon" /> {" "}
                  <p className="d-lg-none">facebook</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    data-placement="bottom"
                    href="https://fr.linkedin.com/in/diahara-lansry-2015a0165"
                    target="_blank"
                    title="Linkedin"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-icon"/> {" "}
                  <p className="d-lg-none">linkedin</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
  );
}

export default IndexNavbar;
