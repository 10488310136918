
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import Navbare from "../components/Navbars/Navbare.js";
import LandingPageHeader from "../components/Headers/LandingPageHeader.js";
import Footer from "../components/Footers/Footer.js";
import {ROUTE_CV} from "../Routes";
import {faFacebook, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmailBlock from "../components/EmailBlock";

function Home() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });
    return (
        <>
            <Navbare />
            <LandingPageHeader />
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title-yuri-4">Qu'est ce que Yuri</h2> <br/>
                                <p className="description">
                                    Nous sommes une équipe passionnée et expérimentée qui se spécialise dans la création d'applications sur mesure pour répondre aux besoins uniques de nos clients. Que vous soyez une petite entreprise, une start-up ou une grande entreprise, nous sommes là pour vous aider à transformer vos idées en réalité numérique.
                                </p>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <h3 className="title-yuri-2">Mes services</h3><br />
                        <Row>
                            <Col md="3" className={"pt-5"}>
                                <div className="info">
                                    <div className="description">
                                        <h5 className="title-yuri-4">Analyse des besoins</h5>
                                        <br/>
                                        <p>
                                            Notre équipe dédiée effectue une analyse approfondie de vos besoins et objectifs spécifiques afin de comprendre parfaitement votre vision. Nous prenons en compte vos exigences en matière de fonctionnalités, d'expérience utilisateur, de design et de plateformes cibles.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" className={"pt-5"}>
                                <div className="info">
                                    <div className="description">
                                        <h5 className="title-yuri-4">Conception et développement</h5>
                                        <br/>
                                        <p>
                                            Nous croyons en l'importance d'une expérience utilisateur fluide et engageante. Notre équipe de concepteurs et de développeurs expérimentés crée des interfaces utilisateur attrayantes et des applications performantes qui répondent aux normes les plus élevées de l'industrie. Nous veillons à ce que votre application soit parfaitement adaptée à vos besoins et à ceux de vos utilisateurs.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" className={"pt-5"}>
                                <div className="info">
                                    <div className="description">
                                        <h5 className="title-yuri-4">Applications web et mobiles</h5>
                                        <br/>
                                        <p>
                                            Nous concevons des applications qui fonctionnent de manière transparente sur différentes plateformes, qu'il s'agisse d'applications web, iOS, Android ou hybrides. Vous pouvez être assuré que votre application aura une portée maximale et atteindra votre public cible, quel que soit le dispositif utilisé.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" className={"pt-5"}>
                                <div className="info">
                                    <div className="description">
                                        <h5 className="title-yuri-4">Tests et assurance qualité</h5>
                                        <br/>
                                        <p>
                                            Avant le lancement de chaque application, nous effectuons des tests rigoureux pour garantir un fonctionnement fluide, des performances optimales et une sécurité renforcée. Nous nous engageons à fournir des produits finaux de haute qualité qui répondent aux normes les plus strictes.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-primary text-center">
                    <Container>
                        <Row>
                            <Col md="12">
                                <Card className="card-profile card-plain">
                                    <CardBody>
                                        <a href={ROUTE_CV}>
                                            <div className="author">
                                                <h3 className="title-yuri-2-white">Diahara Lansry</h3>
                                                <h6 className="card-category">Développeur sénior</h6>
                                            </div>
                                        </a>
                                        <p className="card-description text-center">
                                            Forts de dix années d'expérience en tant que développeur, dont une période de cinq ans en tant que freelance, J'ai eu le privilège de collaborer avec de prestigieux groupes tels que La Poste, PSA et La CNAM
                                            en qualité de consultante pour Capgemini (Sogeti), ainsi que de freelance pour COLAS et d'autre client, j'ai apporté mon expertise à ces entreprises renommées. <br/> Cette décennie d'expérience a été marquée par l'acquisition de compétences approfondies me permettant de vous fournir des conseils techniques pertinents et de développer des applications web et mobiles de qualité supérieure.
                                        </p>
                                        <Button className="btn-link" color="info" href={ROUTE_CV}>
                                            Lire la suite
                                        </Button>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Row>
                                            <Col>
                                                <Button
                                                    className="btn-just-icon btn-neutral ml-1"
                                                    color="link"
                                                    href="https://web.facebook.com/p/Foulane-100078555725948/?_rdc=1&_rdr"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <FontAwesomeIcon icon={faFacebook} className="fa-icon-index"/>
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className="btn-just-icon btn-neutral ml-1"
                                                    color="link"
                                                    href="https://fr.linkedin.com/in/diahara-lansry-2015a0165"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <FontAwesomeIcon icon={faLinkedinIn} className="fa-icon-index"/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-realisation pt-200">
                    <Container>
                        <h3 className="title-yuri-2 text-center">Réalisations</h3><br />
                        <h4 className="title-yuri-2 text-center">Application Mobile</h4>
                        <br />
                        <p className="card-description text-center">
                            Un logiciel conçu spécifiquement pour être utilisé sur des appareils mobiles tels que des smartphones et des tablettes. Les applications mobiles offrent une variété de fonctionnalités et permettent aux utilisateurs d'effectuer diverses tâches, telles que la communication, la productivité, le divertissement, la navigation, les achats, etc.
                        </p>
                        <br />
                        <br />
                        <Row>
                            <Col className="col-md-3">
                                <img
                                    alt="..."
                                    src={require("../assets/img/app/app2.jpeg")}
                                    width="100%"
                                    style={{borderRadius: 20}}
                                />
                            </Col>
                            <Col className="col-md-3">
                                <img
                                    alt="..."
                                    src={require("../assets/img/app/app10.jpeg")}
                                    width="100%"
                                    style={{borderRadius: 20}}
                                />
                            </Col>
                            <Col className="col-md-3">
                                <img
                                    alt="..."
                                    src={require("../assets/img/app/app6.jpeg")}
                                    width="100%"
                                    style={{borderRadius: 20}}
                                />
                            </Col>
                            <Col className="col-md-3" >
                                <img
                                    alt="..."
                                    src={require("../assets/img/app/app11.jpeg")}
                                    width="100%"
                                    style={{borderRadius: 20}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-primary section-information text-center">
                    <Container>
                        <Row className={"pb-5"}>
                            <Col lg="4">
                                <h4 className="title-yuri-2-white">Application Web Responsive</h4>
                                <br/>
                                <p>
                                    Une application informatique conçue pour s'adapter de manière dynamique à différents types d'appareils et de tailles d'écran, tels que les smartphones, les tablettes et les ordinateurs de bureau. Elle utilise des techniques de conception et de développement spécifiques pour garantir une expérience utilisateur optimale, quel que soit le périphérique utilisé.
                                </p>
                                <br/>
                                <div>
                                    <p>Une application responsive offre de nombreux avantages, notamment :</p>
                                    <ul>
                                        <li>Accessibilité : Les utilisateurs peuvent accéder à l'application à partir de divers appareils, ce qui permet une portabilité et une disponibilité accrues.</li>
                                        <li>Expérience utilisateur améliorée : L'application s'ajuste automatiquement à l'écran de l'appareil, offrant une expérience utilisateur cohérente et optimisée.</li>
                                        <li>Réduction des coûts et du temps de développement : Au lieu de créer des applications spécifiques pour chaque plateforme, une seule application responsive peut être développée et utilisée sur plusieurs appareils.
                                        </li>
                                        <li>Maintenance simplifiée : Les mises à jour et les correctifs peuvent être appliqués plus facilement à une seule application plutôt qu'à plusieurs versions pour différents appareils.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="8" >
                                <img
                                    alt="..."
                                    src={require("../assets/img/app/responsive.png")}
                                    width="100%"
                                    style={{borderRadius: 20}}
                                />
                            </Col>
                        </Row>
                        <h4 className="title-yuri-2-white text-center">Mes réalisations personnelles</h4>
                        <br />
                        <Row>
                            <Col lg="2">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/s2.jpg")}
                                            width="100%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Site web de petites annonces</p>
                                        <Button className="btn-link" color="info" href="https://www.dealsamax.com" target="_blank">
                                            Découvrir
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/s4.png")}
                                            width="100%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Marketplace de vente de produits en ligne dans toute l'afrique. Mode, parfum, accessoire etc...</p>
                                        <Button className="btn-link" color="info" href="https://www.foulane.com" target="_blank">
                                            Découvrir
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/s7.png")}
                                            width="100%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Site d'intérim ...</p>
                                        <Button className="btn-link" color="info" href="https://lansry-rh.fr/" target="_blank">
                                            Découvrir
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/s1.png")}
                                            width="100%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>
                                            Application web de prise de commande par le client et listing des plats disponibles
                                        </p>
                                        <Button className="btn-link" color="info">
                                            En cours de dévélooppement
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/s3.png")}
                                            width="100%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Application de gestion d'une buanderie. Notification collecte, livraison, gestion de facture, paye ...</p>
                                        <Button className="btn-link" color="info">
                                            Store
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="info">
                                    <div className="description">
                                        <img
                                            alt="..."
                                            src={require("../assets/img/app/HD.png")}
                                            width="100%"
                                            style={{borderRadius: 20}}
                                        />
                                        <br/>
                                        <br/>
                                        <p>Site web de reservation d'évènement chez HAIIDESIGNS</p>
                                        <Button className="btn-link" color="info" href="#" target="_blank">
                                            En cours de dévélooppement
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <EmailBlock
                    title={"Nous contacter"}
                    description={"Que vous soyez un entrepreneur passionné, un talentueux développeur ou une entreprise en quête d'innovation, nous sommes prêts à écouter vos idées, à explorer de nouvelles possibilités et à trouver des solutions adaptées à vos besoins spécifiques."}
                    description2={"Pour contacter Yuri, vous pouvez simplement envoyer un mail au contact ci-dessous en précisant l'objet de votre demande. Nous nous engageons à vous répondre dans les plus brefs délais et à explorer les différentes façons dont nous pouvons collaborer"}
                    objects={[
                        "Entreprise en quête d'innovation",
                        "Entrepreneur passionné",
                        "Développeur talentueux"
                    ]}
                />
            </div>
            <Footer />
        </>
    );
}

export default Home;
