
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";
import {ROUTE_CV, ROUTE_PRIVACY_POLICY} from "../../Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href={ROUTE_PRIVACY_POLICY}
                >
                  Confidentialité
                </a>
              </li>
              <li>
                <a
                  href={ROUTE_CV}
                >
                  A propos de moi
                </a>
              </li>
              <li>
                <a
                    href={"https://web.facebook.com/p/Foulane-100078555725948/?_rdc=1&_rdr"}
                >
                  <FontAwesomeIcon icon={faFacebook} className="fa-icon" />
                </a>
              </li>
              <li>
                <a
                    href="https://fr.linkedin.com/in/diahara-lansry-2015a0165"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-icon"/>
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, Réalisé par Yuri Agence
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
