import React from "react";
import Footer from "../components/Footers/Footer";
import {Col, Container, Row} from "reactstrap";
import IndexNavbar from "../components/Navbars/IndexNavbar";


function PrivacyPolicy() {

    return <>
        <IndexNavbar />
        <div className="main pt-5">
            <div className="section text-center">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="title-yuri-4">Politique de confidentialité des applications Yuri</h2><br/><br/>
                            <p className="description">
                                Cette politique de confidentialité décrit la manière dont notre agence de développement collecte, utilise, stocke et protège les informations personnelles des utilisateurs de nos applications mobiles et web. Nous nous engageons à respecter la confidentialité de vos données et à les protéger conformément aux lois applicables en matière de protection des données. Veuillez lire attentivement cette politique de confidentialité avant d'utiliser nos applications. <br/>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section section-primary section-information text-center">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h4 className="title-yuri-2-white">Collecte des informations</h4> <br/>
                            <p>Nous pouvons collecter certaines informations personnelles lorsque vous utilisez nos applications, telles que :</p> <br/>

                            <p>1.Informations d'identification</p>
                            <p>nom, adresse e-mail, numéro de téléphone, identifiant unique de l'appareil, etc.</p><br/><br/>
                            <p>2.Informations de localisation</p> <p>avec votre consentement, nous pouvons collecter des informations sur votre emplacement géographique.</p><br/><br/>
                            <p>3.Informations d'utilisation:</p> <p>nous pouvons collecter des informations sur la manière dont vous utilisez nos applications, y compris les actions que vous effectuez et les fonctionnalités que vous utilisez</p><br/><br/>

                            <h4 className="title-yuri-2-white">Utilisation des informations</h4> <br/>
                            <p>Nous utilisons les informations collectées pour les finalités suivantes</p> <br/>

                            <p>1.Fournir et améliorer nos services</p> <p>nous utilisons vos informations pour vous offrir une expérience utilisateur optimale et améliorer nos applications.</p><br/><br/>
                            <p>2.Personnalisation</p> <p>nous pouvons utiliser vos informations pour personnaliser le contenu et les fonctionnalités de nos applications en fonction de vos préférences.</p><br/><br/>
                            <p>3.Communication</p> <p>nous pouvons vous contacter par e-mail, notification push ou autres moyens pour vous fournir des informations importantes concernant nos applications.</p><br/><br/>
                            <p>4.Sécurité</p> <p>nous utilisons les informations pour assurer la sécurité de nos applications et prévenir les activités frauduleuses ou abusives.</p><br/><br/>


                            <h4 className="title-yuri-2-white">Partage des informations</h4><br/>
                            <p>
                                Nous ne vendons ni ne louons vos informations personnelles à des tiers. Cependant, dans certaines circonstances, nous pouvons partager vos informations avec les parties suivantes :
                            </p> <br/>
                            <p>1.Sécurité</p> <p>nous utilisons les informations pour assurer la sécurité de nos applications et prévenir les activités frauduleuses ou abusives.</p><br/><br/>
                            <p>2.Fournisseurs de services tiers</p><p>nous pouvons faire appel à des prestataires de services tiers pour nous aider à fournir nos services. Ces prestataires de services sont soumis à des obligations de confidentialité et ne sont autorisés à utiliser vos informations que dans la mesure nécessaire pour nous aider.</p><br/><br/>
                            <p>3.Conformité légale</p><p>nous pouvons partager vos informations si nous sommes légalement tenus de le faire pour respecter les lois applicables, répondre à une demande légale ou protéger nos droits, notre sécurité ou celle d'autrui.</p>
                            <br/><br/><br/>
                            <h4 className="title-yuri-2-white">Sécurité des informations</h4><br/>
                            <p>
                                Nous prenons des mesures techniques et organisationnelles appropriées pour protéger vos informations personnelles contre la perte, l'accès non autorisé, la divulgation, l'altération ou la destruction. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.
                            </p> <br/><br/>

                            <h4 className="title-yuri-2-white">Vos droits</h4><br/>
                            <p>Vous avez certains droits concernant vos informations personnelles, notamment</p><br/>

                            <p>1.Accès </p><p>vous avez le droit d'accéder aux informations personnelles que nous détenons à votre sujet.</p><br/><br/>
                            <p>2.Rectification </p><p>vous pouvez demander la correction ou la mise à jour de vos informations personnelles inexactes ou incomplètes.</p><br/><br/>
                            <p>3.Suppression </p><p>vous pouvez demander la suppression de vos informations personnelles, sous réserve de certaines exceptions prévues par la loi.</p>
                            <br/><br/>
                            <h4 className="title-yuri-2-white">Modifications de la politique de confidentialité</h4><br/>
                            <p>Nous pouvons mettre à jour cette politique de confidentialité de temps</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        <Footer />
    </>
}

export default PrivacyPolicy;