import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import {ROUTE_CV, ROUTE_HOME, ROUTE_PRIVACY_POLICY, ROUTE_PRICING} from "./Routes";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Pricing from "./views/Pricing";
import AboutMe from "./views/AboutMe";
import Error404 from "./views/Error404";

function App() {
  return (
      <Routes>
        <Route exact path={ROUTE_HOME} element={<Home />} />
        <Route path={ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTE_PRICING} element={<Pricing />} />
        <Route path={ROUTE_CV} element={<AboutMe />} />
        <Route path={"*"} element={<Error404 />} />
      </Routes>
  );
}

export default App;
